import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaCheck, FaMinus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import trialImage from '../Assets/free_trial2.png'
const ChoosePlan = () => {
  return (
    <div className='ChoosePlan py-5' id="pricing">
        <Container className='py-lg-5'>
        <div className="nfromedGuideHeader text-center mb-5">    
            <h2>Choose Your Plan</h2>
            <p className='mb-0'>Simplify Your Product Research </p>
        </div>
        <div className='mt-3 planTabArea'>
            <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3 planTab"
            >
                <Tab eventKey="home" title="Monthly">
                    <div className="planTabContent mt-5">
                        <Row className='g-3 g-lg-4 '>
                            <Col lg="4" md="6">
                                <div className="planCard p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Basic Plan</h4>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        <h6>$ <span className='planAmount'>49.99</span> /month</h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 get-started my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Competitive Analysis tool to compare your store to those of your competitors</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>See all your ASINs currently listed.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>⁠⁠Identify Top Competitors.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>View Recommended Items to Source.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>⁠See Items Frequently Bought with your ASINs.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Compare your store to your competitors.</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <div className="planCard active p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Pro Plan</h4>
                                        <span className="best_seller">Best Seller</span>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        <h6>$ <span className='planAmount'>89.99</span> /month</h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>All the Above in the Entry Level Tier.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>30 ASINs to track.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Analytics to see sales, sellers, inventory, pricing and more.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Product search tabs for detailed ASIN specific data and charts.</span>
                                            </li>
                                        </ul>
                                        {/* <Link to="/sign-up" className='btn nformed-btn w-100 mt-4'>Try For Free</Link> */}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <div className="planCard p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Enterprise Plan</h4>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        <h6>$ <span className='planAmount'>249.99</span> /month</h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 get-started my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>All the Above in both Entry and Mid-Level Tiers.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Plus 100 ASINs.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Onboarding and training support for the first 30 days.</span>
                                            </li>
                                        </ul>
                                        {/* <Link to="/sign-up" className='btn nformed-btn get-started w-100 mt-4'>Try For Free</Link> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey="profile" title={<>Annually <small className='text-danger'>20% off</small></>}>
                    <div className="planTabContent mt-5">
                        <Row className='g-3 g-lg-4 '>
                            <Col lg="4" md="6">
                                <div className="planCard p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Basic Plan</h4>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        <p className='d-flex align-items-center mb-0 fw-semi'>
                                            <span className='planAmount me-2' style={{textDecoration: 'line-through'}}>$600 </span>
                                            <small className="text-danger discountBadge">20% off</small>
                                        </p>
                                        <h6>
                                            $ <span className='planAmount'>479.99</span> / year
                                        </h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 get-started my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Competitive Analysis tool to compare your store to those of your competitors</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>See all your ASINs currently listed.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>⁠⁠Identify Top Competitors.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>View Recommended Items to Source.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>⁠See Items Frequently Bought with your ASINs.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Compare your store to your competitors.</span>
                                            </li>
                                        </ul>
                                        {/* <Link to="/sign-up" className='btn nformed-btn w-100 get-started mt-4'>Try For Free</Link> */}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <div className="planCard active p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Pro Plan</h4>
                                        <span className="best_seller">Best Seller</span>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        {/* <h6><span className='planAmount'>$89.99</span> /month</h6> */}
                                        <p className='d-flex align-items-center mb-0'>
                                            <span className='planAmount me-2' style={{textDecoration: 'line-through'}}>$1080  </span>
                                            <small className="text-danger discountBadge">20% off</small>
                                        </p>
                                        <h6>
                                            $ <span className='planAmount'>863.99</span> / year
                                        </h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>All the Above in the Entry Level Tier.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>30 ASINs to track.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Analytics to see sales, sellers, inventory, pricing and more.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Product search tabs for detailed ASIN specific data and charts.</span>
                                            </li>
                                        </ul>
                                        {/* <Link to="/sign-up" className='btn nformed-btn w-100 mt-4'>Try For Free</Link> */}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <div className="planCard p-4">
                                    <div className="planCardTop p-xxl-2">
                                        <h4>Enterprise Plan</h4>
                                        <img src={trialImage} className="img-fluid trial_image"/>
                                        {/* <h6><span className='planAmount'>$249.99</span> /month</h6> */}
                                        <p className='d-flex align-items-center mb-0'>
                                            <span className='planAmount me-2' style={{textDecoration: 'line-through'}}>$3000   </span>
                                            <small className="text-danger discountBadge">20% off</small>
                                        </p>
                                        <h6>
                                            $ <span className='planAmount'>2399.99</span> / year
                                        </h6>
                                        <Link to="/sign-up" className='btn nformed-btn w-100 get-started my-3'>Try For Free</Link>
                                    </div>
                                    <div className="planCardBottom p-xxl-2">
                                        <ul>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>All the Above in both Entry and Mid-Level Tiers.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Plus 100 ASINs.</span>
                                            </li>
                                            <li>
                                                <span className='icon check'><FaCheck /></span>
                                                <span>Onboarding and training support for the first 30 days.</span>
                                            </li>
                                        </ul>
                                        {/* <Link to="/sign-up" className='btn nformed-btn get-started w-100 mt-4'>Try For Free</Link> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Tab>
            </Tabs>
        </div>
        </Container>
    </div>
  )
}

export default ChoosePlan