import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoIcon from '../../Assets/LogoBlack.png';
import NotificationIcon from '../../Assets/Icon.png';
import { Button } from 'react-bootstrap';
import {FaBars} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import 'animate.css';
import './navbar.css';
import { Link, NavLink } from 'react-router-dom';


function NavBar() {
    const [isOpen,Setisopen] = useState(false);
    // useEffect(() => {
    //     const href = window.location.href.substring(
    //       window.location.href.lastIndexOf('#') + 1
    //     );
    //     const element = document.getElementById(href);
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }, []);
    return (
        <>
            <div className="offer_bar">
                <h5>Start your free 30-day free trial. Use code <span className='shadow'>NFORMED30</span> at checkout <Link to="/sign-up" className='text-white ms-2 sign-up-now'> Sign Up Now</Link></h5>
            </div>
            <div>
                <Navbar className='navbar_main bg-white position-static'  expand="lg">
                    <Container className='d-block '>
                        <Row className='align-items-center'>
                            <Col xs={'auto'} md={'3'} className='me-auto'>
                                <div className="logo d-flex">
                                <NavLink to='/' className="logoLink"> <img src={LogoIcon} alt="Logo" className='img-fluid' style={{"margin": "20px 0px "}}/></NavLink>
                                </div>
                            </Col>
                            <Col sm={12} md={'9'} className="d-lg-flex justify-content-end d-none ">
                                <div className="buttons_group d-flex">
                                    <ul className="link_button d-flex" style={{"listStyle":"none","margin":"20px 0px","fontSize":"14px", "alignItems":"center"}}>
                                        <li className='upload_csv'><Nav.Link  href='/#saler_dashboard'>For Sellers</Nav.Link></li>
                                        <li className='upload_csv'><Nav.Link  href='/#brand_dashboard'>For Brands</Nav.Link></li>
                                        <li className='dataAnatics upload_csv'>
                                            <Nav.Link href="/#onlinesalesdata">Data Analytics</Nav.Link>
                                        </li>
                                        <li className='upload_csv'><Nav.Link  href='/#salestracking'>Track Sales</Nav.Link></li>
                                    </ul>
                                    
                                    <div className="dash_button">
                                        <Button variant="success" href={process.env.REACT_APP_BACKEND_URL} className='dashboardbtn'>Dashboard</Button>
                                    </div>
                                    <div className="bell_icon">
                                        <img src={NotificationIcon} alt="Notification Icon" className='bellimg'/>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={'auto'} className='d-lg-none d-flex'>
                                <div className="logo d-flex">
                                {
                                    !isOpen ? <FaBars onClick={(e)=>Setisopen(true)}/> : null
                                }
                                </div>
                            </Col>
                            {
                                isOpen ? <div className="sidebar animate__animated animate__fadeInRight">
                                    <div className="sidebarlogo">
                                    <ImCross className='sidebarCross' onClick={(e)=>Setisopen(false)}/>
                                    <img src={LogoIcon} alt="Logo" style={{"margin": "27px 0px 0px 39px"}}/>
                                    </div>
                            

                                <hr />
                                <ul className='menuCon'>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><NavLink to="/" className="text-decoration-none text-white">Home</NavLink></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='/#onlinesalesdata' >Data Analytics</Nav.Link></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='/#salestracking'>Track Sales</Nav.Link></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='https://dashboard.nformed.com/'>Dashboard</Nav.Link></li>
                                </ul>
                            </div> : null
                            }
                        </Row>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default NavBar
